<template>
  <v-footer
      class="ma-0"
      id="footerInfo"
  >
    <v-container fluid>
      <v-row class="offset-lg-2 ps-lg-6">
        <v-col cols="12" md="4">
          <small>© Copyright {{ new Date().getFullYear() }}. Benedikt Binzer</small>
        </v-col>
        <v-col cols="12" md="8" class="text-end subtitle-2">
          <small>
            <router-link to="/impressum" class="mb-3 d-inline me-2">Impressum</router-link>

            <a @click="$router.push('/datenschutzerklaerung')"
               class=" d-inline mb-3 me-2"
               title="Datenschutzerklärung ">Datenschutzerklärung</a>
            <a
                href="https://www.iubenda.com/privacy-policy/14714568/cookie-policy"
                class="iubenda-nostyle d-inline no-brand iubenda-noiframe iubenda-embed iubenda-noiframe mb-3 me-2"
                title="Cookie-Richtlinie ">Cookie-Richtlinie</a>
            <a @click="$router.push('/agb')"
               class=" d-inline mb-3 me-2"
               title="AGB ">AGBs / Geschäftsbedingungen</a>
          </small>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
        v-model="dialogDemo"
        :max-width="1200"
    >
      <iframe width="540" height="1000vh"
              src="https://meet.sendinblue.com/benedikt-binzer?t=0&l=demo-weddingflow-mit-benedikt-binzer"
              frameborder="0" scrolling="auto" allowfullscreen
              style="display: block;margin-left: auto;margin-right: auto;max-width: 100%; background-color: white"></iframe>
    </v-dialog>
  </v-footer>
</template>

<script>

export default {
  name: "Footer",
  data() {
    return {
      dialogDemo: false
    }
  },
  components: {},
  methods: {
    openDialogDemo() {
      this.dialogDemo = true
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  display: block;

  &:hover {
    color: #B19983;
  }
}

.logo {
  font-size: 1.5rem;
}
</style>
